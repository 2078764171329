var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "container-fluid", attrs: { id: "company-details-slide" } },
    [
      _c("slide-heading", { attrs: { heading: _vm.currentSlide.heading } }),
      _vm.loaded
        ? [
            _c(
              "div",
              { staticClass: "slide-description mb-5 justify-content-center" },
              [
                _c("div", {
                  domProps: { innerHTML: _vm._s(_vm.slideDescription) },
                }),
              ]
            ),
            _c("company-details"),
            _c("div", [
              _c("p", { staticClass: "my-5" }, [
                _vm._v(
                  "\n        If all of the above information looks correct, click "
                ),
                _c("strong", [_vm._v("Looks Good")]),
                _vm._v(
                  " to complete\n        the CTA Guide and return  to your Company page. Otherwise, click "
                ),
                _c("strong", [_vm._v("Make Changes\n        ")]),
                _vm._v(
                  " to file a Beneficial Ownership Information Report.\n      "
                ),
              ]),
              _c(
                "div",
                { staticClass: "buttons d-flex justify-content-center" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "btn-outline-primary",
                      attrs: {
                        variant: "btn-outline-primary",
                        "aria-label": "looks good button",
                      },
                      on: { click: _vm.completeAndRedirectToStageline },
                    },
                    [_vm._v("\n          Looks Good!\n        ")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "btn-primary",
                      attrs: {
                        variant: "btn-primary",
                        "aria-label": "make changes button",
                      },
                      on: { click: _vm.makeChanges },
                    },
                    [_vm._v("\n          Make Changes\n        ")]
                  ),
                ],
                1
              ),
            ]),
          ]
        : _c("ct-centered-spinner", [
            _vm._v("\n    " + _vm._s(_vm.loadingText) + "\n  "),
          ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }